<template>
  <div id="01">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="alerts-device">
          <b-card-body>
            <div class="alerts-info-top">
              <span class="alerts-icon">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="text-alerts"> {{ $t("Alerts") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body class="alerts-table">
          <b-card-body>
            <b-row class="alerts-buttons">
              <b-col cols="4">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-center class="alerts-btn"
                  variant="none">
                  <span>{{ $t("Alerts") }}</span>
                </b-button>
              </b-col>
            </b-row>
            <table class="table-alert d-md-table">
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="alert in alerts" :key="alert.id" class="table-body">
                <td class="alert-column">
                  <div class="table-icons">
                    <img class="icon-table" src="@/assets/images/icons/alert-red.png" alt=""
                      v-if="alert.level == 'NOK'" />
                    <img class="icon-table" src="@/assets/images/icons/alert-icon.png" alt=""
                      v-else-if="alert.level == 'Ups'" />
                    <img class="icon-table" src="@/assets/images/icons/alert-green.png" alt="" v-else />
                    <div class="alert-text">
                      <span class="text-alert">
                        {{ $t(alert.alert_model.name) }}
                      </span>
                      <!-- <a href="javascript:void(0)" class="alert-link" @click=gobacktodevice()>{{ $t("Go to Analysis")
                      }}</a> -->
                    </div>
                  </div>
                </td>
                <td width="80%">
                  <table class="table-alert">
                    <tr>
                      <td class="table-title"></td>
                      <td class="table-title">{{ $t("Initial Date") }}</td>
                      <td class="table-title">{{ $t("End Date") }}</td>
                    </tr>
                    <td class="textarea-column">
                      <b-form-textarea v-on:click="edit(alert)" v-show="alert.level == 'Resolved'" class="alert-textarea"
                        no-resize :placeholder="
                          alert.selected_action
                            ? $t(alert.selected_action.description)
                            : ''
                        " readonly />
                    </td>
                    <td class="date-column">
                      <span>{{ formatDate(alert.create_date) }}</span>
                    </td>
                    <td class="date-column">
                      <span>{{
                        alert.resolution_date ? formatDate(alert.resolution_date) : "-"
                      }}</span>
                    </td>
                    <td class="check-column">
                      <b-form-checkbox value="A" @change="appendCheckedList(alert)"> </b-form-checkbox>
                    </td>
                  </table>
                  <table v-if="alert.level != 'Resolved'" class="table-alert register">
                    <thead>
                      <tr>
                        <td class="table-title big">
                          {{ $t("Corrective Action") }}
                        </td>
                        <td class="table-title big">
                          {{ $t("Share Registration") }}
                        </td>
                      </tr>
                    </thead>
                    <tr>
                      <td width="60%">
                        <p v-for="(
                                      action, key
                                    ) in alert.alert_model.corrective_actions.filter(
                                      (el) => el.is_user_action == false
                                    )" :key="action.id">
                          {{ key + 1 }}. {{ $t(action.description) }}
                        </p>
                      </td>
                      <td width="40%">
                        <v-select
                          class="alert-select-action"
                          :placeholder="$t('Select an option')"
                          :options="alert.alert_model.corrective_actions.filter(
                              (el) => el.is_user_action == true
                          )"
                          :getOptionLabel="(option) => `${$t(option.description)}`"
                          :searchable="false" @input="(action) => selectAction(alert, action)"
                          maxHeight="36px"
                        >
                          <template slot="option" slot-scope="option">
                            {{ $t(option.description) }}
                          </template>
                        </v-select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button type="button" class="btn search-btn m-0" @click="saveAction(alert)">
                          <span>{{ $t("Save") }}</span>
                        </button>
                        <button type="button" class="btn search-btn" @click="cancelAction(alert)">
                          <span>{{ $t("Cancel") }}</span>
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <b-row v-if="alerts.length == 0" class="text-center">
              {{ $t("No alerts in period") }}
            </b-row>
            <!-- Footer -->
            <div class="table-subtitle">
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/nok.png" alt="" />
                <span class="sub-text">{{ $t("Active alert unresolved") }} NOK</span>
              </div>
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/ups.png" alt="" />
                <span class="sub-text">{{ $t("Active alert unresolved") }} {{ $t("UPS") }}</span>
              </div>
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/ok.png" alt="" />
                <span class="sub-text">{{ $t("Alert resolved") }}</span>
              </div>
              <div class="table-sub">
                <feather-icon class="alert-download" icon="DownloadIcon" @click="downloadPDF" />
              </div>
              <div class="table-sub">
                <b-button class="clear-btn" variant="none" @click="cleanAlerts">
                  <span>{{ $t("Clear") }}</span>
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal-center" centered ok-only ok-title=" Great, let's continue!">
      <div class="modal-text">
        <span class="modal-title">{{ $t("Equipment’s alerts") }}</span>
        <span class="modal-description"> {{ $t(`Here you will find all of your equipment’s alerts.`)}} </span>
        <span class="modal-description"> {{ $t(`If an alert is active, it will be listed and allow you to save the performed actions for maintenance control & cost management.`) }}</span>
        <br>
      </div>
      <div class="modal-alert-body">
        <span class="modal-alert-title">{{
          $t("There are two types of alerts:")
        }}</span>
        <div class="modal-alerts">
          <img class="icon-modal" src="@/assets/images/icons/nok.png" alt="" />
          <div class="modal-alert-text">
            <span class="modal-title-alert nok">NOK</span>
            <span class="modal-alert-description">{{
              $t(
                "Something seriously wrong, follow the recommendations quickly"
              )
            }}</span>
          </div>
        </div>
        <div class="modal-alerts">
          <img class="icon-modal" src="@/assets/images/icons/ups.png" alt="" />
          <div class="modal-alert-text">
            <span class="modal-title-alert ups">{{ $t("UPS") }}</span>
            <span class="modal-alert-description">{{
              $t(
                "Something is not perfect, follow the recommendations to avoid future"
              )
            }}</span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  VBModal,
  BModal,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BFormTextarea,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BModal,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormTextarea,
    BFormCheckbox,
    VBTooltip,
    vSelect,
    BFormGroup,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      infoNOK: false,
      infoUPS: false,
      infoOK: false,
      actions: {},

      alertsChecked: [],
    };
  },
  methods: {
    toggleInfoNOK() {
      this.infoNOK = !this.infoNOK;
    },
    toggleInfoUPS() {
      this.infoUPS = !this.infoUPS;
    },
    toggleInfoOK() {
      this.infoOK = !this.infoOK;
    },
    getAlerts() {
      let intervalTime = 0;
      if (this.timeInterval > 2)
        intervalTime = this.timeInterval == 168 ? "last_week" : "last_month"
      else
        intervalTime = "2h"
      let deviceId = this.$route.params.id;
      this.$store.dispatch("alerts/listAlerts", { context: this, device_id: deviceId, interval: intervalTime })
    },
    selectAction(alert, action) {
      if (!action) {
        this.actions[alert.id] = action;
        return;
      }
      this.actions[alert.id] = action.id;
    },
    saveAction(alert) {
      let payload = {
        action: this.actions[alert.id],
      };
      this.$http
        .patch(`${process.env.VUE_APP_API_URL}api/alerts/${alert.id}/`, payload)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alert action",
              icon: "RefreshCwIcon",
              variant: "success",
              text: "Action selected successfully",
            },
          });
          this.getAlerts();
        });
    },
    formatDate(value) {
      const dateTZ = new Date(value);
      const dayOfMonth =
        dateTZ.getDate() > 9 ? dateTZ.getDate() : `0${dateTZ.getDate()}`;
      const monthOfYear =
        dateTZ.getMonth() + 1 > 9
          ? dateTZ.getMonth() + 1
          : `0${dateTZ.getMonth() + 1}`;
      const year = dateTZ.getFullYear();
      const hourOfDay =
        dateTZ.getHours() > 9
          ? dateTZ.getHours()
          : `0${dateTZ.getHours()}`;
      const minuteOfHour =
        dateTZ.getMinutes() > 9
          ? dateTZ.getMinutes()
          : `0${dateTZ.getMinutes()}`;
      return `${year}-${monthOfYear}-${dayOfMonth} ${hourOfDay}:${minuteOfHour}`;
    },
    edit(alert) {
      alert.resolution_date_cancel = alert.resolution_date
      alert.level_cancel = alert.level
      alert.resolution_date = null
      alert.level = null
    },
    cancelAction(alert) {
      alert.resolution_date = alert.resolution_date_cancel
      alert.level = alert.level_cancel
    },
    cleanAlerts() {
      if (this.alertsChecked.length == 0) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: "Alert action",
            icon: "RefreshCwIcon",
            variant: "danger",
            text: "Select at least 1 alert",
          },
        });

      }
      let payload = {
        alerts_id: this.alertsChecked,
      };
      this.$http
        .post(`${process.env.VUE_APP_API_URL}api/alerts/hide/`, payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alert action",
              icon: "RefreshCwIcon",
              variant: "success",
              text: "Alerts Cleaned",
            },
          });
          this.alertsChecked.forEach(element => {
            this.$store.commit(
              "alerts/SET_ALERTS_LIST",
              this.alerts.filter(x => x.id != element)
            );
          });;
          this.alertsChecked = []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alert action",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error,
            },
          });
          this.getAlerts();
        });
    },
    appendCheckedList(alert) {
      if (this.alertsChecked.indexOf(alert.id) == -1)
        this.alertsChecked.push(alert.id)
      else
        this.alertsChecked = this.alertsChecked.filter(item => item != alert.id)
    },
    gobacktodevice() {
      this.$router.push({
        name: "device",
        params: { id: this.$route.params.id },
      });
    },
    downloadPDF() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Processing Download',
          icon: 'Download',
          variant: 'success',
        },
      });
      let intervalTime = 0;
      if (this.timeInterval > 2)
        intervalTime = this.timeInterval == 168 ? "last_week" : "last_month"
      else
        intervalTime = "2h"
      this.$store.dispatch("alerts/report", { context: this, device_id: this.$route.params.id, interval: intervalTime })
        .then(
          response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            let file_name = `${this.currentDevice.model_code}-${this.currentDevice.name ? this.currentDevice.name : this.currentDevice.mac_number}.pdf` 
            link.download = file_name
            link.click()
            URL.revokeObjectURL(link.href)
          })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data
            },
          })
        });
    }
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    alerts() {
      return this.$store.state.alerts.alertList;
    },
    currentDevice() {
      return this.$store.state.devices.currentDevice
    }
  },
  watch: {
    timeInterval() {
      this.getAlerts();
    },
  },
  created() {
    this.getAlerts();
  }
};
</script>
<style scoped>
.alert-select-action {
  width: 100% !important;
  max-width: 100% !important;
  height: 36px !important;
  max-height: 36px !important;
}

.text-center {
  justify-content: center;
}
</style>