<template>
  <div>
    <div class="device-fixed" :class="{ fixed: deviceFixed, show: deviceFixedShow }" id="device-fixed">
      <b-row class="match-height">
        <b-col cols="12" xl="12">
          <b-card no-body>
            <b-card-body>
              <div class="device-top d-flex flex-column flex-xl-row align-items-xl-center align-items-start">
                <b-card-title class="text-device mb-0">
                  {{ device ? device.name || device.model_code  : ""}}
                </b-card-title>
                <div class="device-right">
                  <b-button class="device-btn" variant="none" data-id="01" @click="gobacktodevice()">
                    <feather-icon class="device-icon" icon="CheckSquareIcon" />
                    <span class="align-middle">{{ $t("Fit for Use") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="02" @click="gobacktodevice()">
                    <feather-icon class="device-icon" icon="CreditCardIcon" />
                    <span class="align-middle">{{ $t("Ambient") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="03" @click="gobacktodevice()">
                    <feather-icon class="device-icon" icon="TrendingUpIcon" />
                    <span class="align-middle">{{ $t("Performance") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="04">
                    <feather-icon class="device-icon" icon="BellIcon" />
                    <span class="align-middle">{{ $t("Alerts") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="05" @click="goToReports()">
                    <feather-icon class="device-icon" icon="PieChartIcon" />
                    <span class="align-middle">{{ $t("Reports") }}</span>
                  </b-button>
                  <b-button class="device-btn mr-0" variant="none" data-id="06" @click="gobacktodevice()">
                    <feather-icon class="device-icon" icon="CheckSquareIcon" />
                    <span class="align-middle">{{ $t("Details") }}</span>
                  </b-button>
                  <v-select class="date-select" :options="[
                    { value: 2, label: $t('2h') },
                    { value: 168, label: $t('7 days') },
                    { value: 720, label: $t('30 days') },
                  ]" v-model="timeInterval" :reduce="(time) => time.value" :searchable="false" />
                </div>
                <div class="device-info-wrapper relative d-flex">
                  <p class="serial-number">
                    {{ $t("Serial") }}:
                    <span>{{ device.serial_number }}</span>
                  </p>
                  <p class="model-number">
                    {{ $t("Model") }}:
                    <span>{{ device.model_code }}</span>
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal v-model="showModal" id="modal-center-id" ok-title="Great let's continue!" class="justify-content-center"
      @ok="onCloseModal" ok-only centered v-on:close="onCloseModal" no-close-on-backdrop>
      <div class="modal-text">
        <span class="modal-title">{{ $t("Equipment's alerts") }}</span><br />
        <span class="modal-description">
          {{ $t("Here you will find all of your equipment's alerts.") }}<br />
          {{ $t("If an alert is active, it will be listed and allow you to save the performed actions for maintenance control & cost management.") }}<br /><br />
        </span>
      </div>
      <div class="modal-alert-body">
        <span class="modal-alert-title">{{
          $t("There are two types of alerts:")
        }}</span>
        <div class="modal-alerts">
          <img class="icon-modal" src="@/assets/images/icons/nok.png" alt="" />
          <div class="modal-alert-text">
            <span class="modal-title-alert nok">NOK</span>
            <span class="modal-alert-description">{{
              $t(
                "Something seriously wrong, follow the recommendations quickly"
              )
            }}</span>
          </div>
        </div>
        <div class="modal-alerts">
          <img class="icon-modal" src="@/assets/images/icons/ups.png" alt="" />
          <div class="modal-alert-text">
            <span class="modal-title-alert ups">{{ $t("UPS") }}</span>
            <span class="modal-alert-description">{{
              $t(
                "Something is not perfect, follow the recommendations to avoid future"
              )
            }}</span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";


export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VueApexCharts,
    vSelect
  },
  directives: {
    Ripple,
  },
  computed: {
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    device() {
      return this.$store.state.devices.currentDevice;
    },
    currentMetricGenericDevice() {
      return this.$store.state.devices.currentMetricGenericDevice;
    },
    timeInterval: {
      get() {
        return this.$store.state.devices.timeInterval;
      },
      set(data) {
        this.$store.commit("devices/SET_TIME_INTERVAL", data);
      },
    },
    currentUserShowModals() {
      return this.$store.state.users.currentUserShowModals
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },
  data() {
    return {
      deviceFixed: false,
      deviceFixedShow: false,
      goal_overview: {
        min_temp: 0,
        max_temp: 0,
      },
      current_temp: 0,
      showModal: false,
    };
  },
  watch: {
    currentUser(newProp, oldProp) {
      return this.verifyModal();
    },
  },
  methods: {
    goToReports() {
      this.$router.push({
        name: "device-reports",
        params: { id: this.$route.params.id },
      });
    },
    gobacktodevice() {
      this.$router.push({
        name: "device",
        params: { id: this.$route.params.id },
      });
    },
    getData() {
      this.current_temp = this.currentMetricGenericDevice.current_internal_temp;
      this.goal_overview.min_temp =
        this.currentMetricGenericDevice.min_internal_temp;
      this.goal_overview.max_temp =
        this.currentMetricGenericDevice.max_internal_temp;
    },
    onCloseModal() {
      let data = { alerts_page: true }
      this.$store.dispatch("users/setPageView", { context: this, data, id: this.currentUserShowModals.id })
    },
    verifyModal() {
      this.$store.dispatch("users/getCurrentUserPages", { context: this })
        .then(() => {
          let currentUserShowModals = this.$store.state.users.currentUserShowModals
          if (currentUserShowModals) {
            this.showModal = !currentUserShowModals.alerts_page
          }
        })
    },
  },
  created() {
    this.$store.dispatch("recipes/getRecipes", { context: this }).then(() => {
      this.getData();
      this.verifyModal();
    });
  },
};
</script>
